@import '../ThemeVariable.css';

.ondemand-page {
  background-color: var(--bgColorNormal);
  color: #fff;
  font-family: 'Roboto', Arial, sans-serif;
  text-align: center;
  padding: 20px;
}

/* Titles */
.ondemand-page h1 {
  font-size: xx-large;
  font-weight: bold;
  margin-bottom: 15px;
}

.ondemand-page h2 {
  font-size: x-large;
  font-weight: normal;
  margin-bottom: 10px;
  color: #ccc; /* Subtle text for secondary title */
}

/* Tab buttons container */
.tab-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Adjusts for smaller screens */
  gap: 10px; /* Spacing between buttons */
  margin: 15px 0 20px;
}

/* Tab buttons */
.tab-buttons button {
  padding: 10px 25px;
  border-radius: 30px;
  border: 2px solid transparent;
  background-color: #404040;
  color: #fff;
  font-size: large;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tab-buttons button:hover {
  background-color: #606060;
  border-color: #fff; /* Highlight on hover */
  transform: scale(1.05);
}

.tab-buttons button.active {
  background-color: orange;
  color: #000;
  font-weight: bold;
  box-shadow: 0px 4px 10px rgba(255, 165, 0, 0.5); /* Orange glow */
}

/* Video container */
.video-container {
  margin: 20px auto;
  width: 70%; /* Adjusts width to fit the layout */
  max-width: 800px; /* Limit the max size for large screens */
  aspect-ratio: 16 / 9; /* Maintains 16:9 aspect ratio */
  border: 4px solid #606060;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  background-color: #000; /* Black background behind video */
}

.video-container video {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover; /* Ensures video covers the container */
}

/* Button container inside video player */
.button-container {
  position: absolute;
  bottom: 10px; /* Position buttons at the bottom */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 15px;
  justify-content: center;
  z-index: 10;
}

/* Control buttons */
.icon-button {
  background-color: #404040;
  border: none;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.icon-button:hover {
  background-color: #606060;
  transform: scale(1.15);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.6);
}

.icon-button:disabled {
  background-color: #2c2c2c;
  cursor: not-allowed;
}

/* Warning message */
.warning {
  margin: 20px auto;
  background-color: rgba(255, 69, 0, 0.2); /* Light red background */
  color: #ff4500; /* Bright red text */
  border-radius: 10px;
  font-size: large;
  max-width: 600px;
}

/* Adjust spacing between sections */
.control-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

button {
  outline: none;
  border: none;
}

